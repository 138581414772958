import Order from "@/models/order/Order";

export default function initializeActiveOrdersTable() {
  const tableOptions = {
    headings: {
      'name': 'Project name',
      'orders': 'Active billable orders',
    },
    sortable: {
      'name': false,
      'orders': false,
    },
    editable: {
      'name': false,
      'orders': false,
    }
  };

  return {tableOptions};
}

export const dummyOrders =
  [
    {
      "id": 2,
      "completed": 0,
      "isBillable": 1,
      "title": "Creating design for App/Play store images and other stuff",
      "spentHours": 24.75,
      "reductedHours": 7,
      "availableHours": 25,
      "orderType": {
        "id": 2,
        "type": "Estimate"
      }
    },
    {
      "id": 3,
      "completed": 1,
      "isBillable": 0,
      "title": "Completed Test",
      "spentHours": 1,
      "reductedHours": 0,
      "availableHours": 10,
      "orderType": {
        "id": 3,
        "type": "Afterpay"
      }
    },
    {
      "id": 4,
      "completed": 0,
      "isBillable": 1,
      "title": "Another order",
      "spentHours": 2.25,
      "reductedHours": 0,
      "availableHours": 0,
      "orderType": {
        "id": 3,
        "type": "Afterpay"
      }
    },
    {
      "id": 5,
      "completed": 0,
      "isBillable": 1,
      "title": "fff1212",
      "spentHours": 0,
      "reductedHours": 0,
      "availableHours": 1.12,
      "orderType": {
        "id": 3,
        "type": "Afterpay"
      }
    },
    {
      "id": 6,
      "completed": 0,
      "isBillable": 1,
      "title": "Hello",
      "spentHours": 0,
      "reductedHours": 0,
      "availableHours": 0,
      "orderType": {
        "id": 2,
        "type": "Estimate"
      }
    },
    {
      "id": 7,
      "completed": 0,
      "isBillable": 1,
      "title": "dsfsdf",
      "spentHours": 0,
      "reductedHours": 0,
      "availableHours": 0,
      "orderType": {
        "id": 1,
        "type": "Punch card"
      }
    },
    {
      "id": 8,
      "completed": 0,
      "isBillable": 1,
      "title": "ffff",
      "spentHours": 0,
      "reductedHours": 0,
      "availableHours": 0,
      "orderType": {
        "id": 1,
        "type": "Punch card"
      }
    },
    {
      "id": 9,
      "completed": 0,
      "isBillable": 0,
      "title": "New non-billable order",
      "spentHours": 0,
      "reductedHours": 0,
      "availableHours": 0,
      "orderType": {
        "id": 1,
        "type": "Punch card"
      }
    },
    {
      "id": 10,
      "completed": 0,
      "isBillable": 1,
      "title": "New billable order",
      "spentHours": 0.75,
      "reductedHours": 0,
      "availableHours": 0.5,
      "orderType": {
        "id": 2,
        "type": "Estimate"
      }
    },
    {
      "id": 11,
      "completed": 0,
      "isBillable": 1,
      "title": "test new order type",
      "spentHours": 0,
      "reductedHours": 0,
      "availableHours": 5,
      "orderType": {
        "id": 4,
        "type": "Service Level Agreement"
      }
    },
    {
      "id": 12,
      "completed": 0,
      "isBillable": 1,
      "title": "SLA",
      "spentHours": 0,
      "reductedHours": 0,
      "availableHours": 11,
      "orderType": {
        "id": 4,
        "type": "Service Level Agreement"
      }
    }
  ].map(order => Order.parseDataFromObject(order));