import vuexStore from "../../store";
import {computed} from "@vue/composition-api";
import {GET_ACTIVE_ORDERS} from "@/store/activeOrders/actions";
import {dummyOrders} from "@/composables/activeOrders/getActiveOrdersTable";

export default function getActiveOrders() {
  const store = vuexStore;

  // const activeOrders = computed(() => dummyOrders);
  const activeOrders = computed(() => store.getters.getActiveOrders);
  const hasActiveOrders = computed(() => activeOrders.value.length > 0);

  function retrieveActiveOrders(search, page) {
    return store.dispatch(GET_ACTIVE_ORDERS, {search, page});
  }

  return {
    activeOrders,
    hasActiveOrders,

    retrieveActiveOrders,
  }
}