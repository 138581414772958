<template>
  <div>
    <!-- Page title - Displayed when there are active orders or the search is active. -->
    <h1 v-if="hasActiveOrders || searchedTerm" class="main-title page-title">Active orders</h1>

    <!-- Search - Displayed when there are active orders or the search is active. -->
    <SearchInputField v-if="hasActiveOrders || searchedTerm"
                      @search="performSearch"
                      :include-suggestions="false"
                      :result-count="searchedTerm ? activeOrders.length : null"
                      placeholder="Search for a project"
                      id="projectSearch"
                      class="search-project"/>

    <!-- No active orders - Displayed when there are no active orders or the search is active. -->
    <NoRecordsYet v-if="!hasActiveOrders && !searchedTerm" @create="$emit('create')"
                  :button-icon="require('../../assets/icons/svg/ic_add_white.svg')"
                  title="No active orders yet"
                  text="Start from adding your first project and<br/> managing hour logs."
                  button-text="Create project"/>

    <!-- Active orders table - Displayed when there are active orders. -->
    <CustomTable v-else :options="tableOptions" :table-data="activeOrders" class="active-orders-table">
      <template #name="{rowData}">
        <DLink :text="rowData.name" :router-link="{name: ROUTE_NAMES_ORDER.ORDER_OVERVIEW, params: {projectId: rowData.id}}" class="d-link"/>
      </template>
      <template #orders="{rowData}">
        <div class="active-orders-wrapper">
          <OrderCard v-for="order in rowData.orders"
                     :key="order.id"
                     :chip-text="order.orderType.type"
                     :chip-class="order.orderType.color"
                     :title="order.title"
                     :progress-data="order.progressData"
                     @click.native="navigateToOrderDetails(rowData.id, order)"
                     class="active-order"/>
        </div>
      </template>
    </CustomTable>

    <!-- No results indication -->
    <p v-if="!hasActiveOrders && searchedTerm" class="no-results-table-text">There are no results for '{{ searchedTerm }}'</p>

    <!-- Pagination -->
    <Pagination v-if="paginationData && activeOrders.length > 0" :pagination-data="paginationData" :go-to-page="goToPage"/>
  </div>
</template>

<script>
import getActiveOrders from "@/composables/activeOrders/getActiveOrders";
import SearchInputField from "@/components/partials/search/SearchInputField";
import {ref} from "@vue/composition-api";
import initializeActiveOrdersTable from "@/composables/activeOrders/getActiveOrdersTable";
import CustomTable from "@/components/partials/table/CustomTable";
import OrderCard from "@/components/partials/order/OrderCard";
import {ROUTE_NAMES_ORDER} from "@/router/modules/order";
import NoRecordsYet from "@/components/partials/NoRecordsYet";
import Pagination from "@/components/partials/pagination/Pagination";
import {computed} from "@vue/composition-api";
import DLink from "@/components/elements/basicUI/DLink";

export default {
  name: "ActiveOrdersOverview",
  components: {
    DLink,
    Pagination,
    NoRecordsYet,
    OrderCard,
    CustomTable,
    SearchInputField
  },
  setup(props, {root}) {
    const router = root.$router;
    const store = root.$store;

    /** Table **/
    const {tableOptions} = initializeActiveOrdersTable();

    /** Pagination **/
    const activePage = ref(1);
    // const isPaginationDisplayed = computed(() => paginationData.value?.totalPages > 1 && projectsArray.value.length); // The pagination should be displayed if there are more than 2 pages.
    const paginationData = computed(() => store.getters.getPaginationData);

    function goToPage(page) {
      activePage.value = page;
      retrieveActiveOrders(searchedTerm.value, activePage.value);
    }

    /** Search **/
    const searchedTerm = ref('');

    function performSearch(text) {
      searchedTerm.value = text;
      retrieveActiveOrders(searchedTerm.value, activePage.value);
    }

    /** Active orders **/
    const {activeOrders, hasActiveOrders, retrieveActiveOrders} = getActiveOrders(); // Use the composable to retrieve the active orders.
    retrieveActiveOrders(searchedTerm.value, activePage.value);

    function navigateToOrderDetails(projectId, order) {
      router.push({name: ROUTE_NAMES_ORDER.ORDER_DETAILS, params: {projectId, orderId: order.id}});
    }

    return {
      ROUTE_NAMES_ORDER,

      /** Table **/
      tableOptions,

      /** Pagination **/
      paginationData,
      goToPage,

      /** Active orders **/
      activeOrders,
      hasActiveOrders,
      navigateToOrderDetails,

      /** Search **/
      searchedTerm,
      performSearch,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.page-title {
  text-align: left;
}

// Search
.search-project {
  margin-bottom: rem(24);
  margin-top: rem(65);
}

// Active orders cell
.active-orders-wrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: rem(20);

  .active-order {
    display: flex;
    flex-direction: column;
    height: rem(142);
    padding: rem(16);

    ::v-deep .title {
      font-size: rem(16);
      line-height: rem(19);
    }

    ::v-deep .progress-wrapper {
      margin-top: auto;
    }
  }
}
</style>