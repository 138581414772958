export default class ChipOption {
  constructor(text, customClass = CHIP_STYLES.PURPLE, icon = null) {
    this.text = text;
    this.customClass = customClass;
    this.icon = icon;
  }
}

export const CHIP_STYLES = {
  PURPLE: 'purple',
  BROWN: 'brown',
  LIGHT_BLUE: 'light-blue',
  YELLOW: 'yellow',
  LIGHT_RED: 'light-red',
  LIGHT_GRAY: 'light-gray',
  ORANGE: 'orange',
}
