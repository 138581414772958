<template>
  <div :class="customClass" @click="isLink ? $emit('clicked') : () => {}" class="chip-box">
    <img v-if="icon" :src="icon" class="icon" alt=""/>
    <p class="text">{{text}}</p>
  </div>
</template>

<script>
export default {
  name: "Chip",
  props: {
    text: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    isLink: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['clicked']
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";
@import "../../assets/css/layout.shared";

.chip-box {
  @extend .mont-bold;
  align-items: center;
  border-radius: rem(3);
  display: flex;
  justify-content: center;
  padding: rem(5) rem(7);
  min-width: rem(89);

  .text {
    font-size: rem(10);
    line-height: rem(13);
    text-transform: uppercase;
  }

  .icon {
    height: rem(12);
    margin-right: rem(6);
    width: rem(12);
  }
}

//--------------------------------------------------------
// Chip styles
//--------------------------------------------------------
.purple {
  background-color: var(--purple-light);

  .text {
    color: var(--purple-dark);
  }
}

.brown {
  background-color: var(--brown-light);

  .text {
    color: var(--brown-dark);
  }
}

.light-blue {
  background-color: var(--blue-light);

  .text {
    color: var(--blue-dark);
  }
}

.yellow {
  background-color: var(--yellow-light);

  .text {
    color: var(--yellow-dark);
  }
}

.light-red {
  background-color: var(--red-light);

  .text {
    color: white;
  }
}

.light-gray {
  background-color: rgba(56,63,79,0.1);

  .text {
    color: var(--black-light);
    font-size: rem(11);
    text-transform: none;
  }
}

.orange {
  background-color: var(--orange-light);

  .text {
    color: var(--orange-dark);
  }
}
</style>
