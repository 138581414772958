<template>
  <div @click="goToPage(page)" :class="[className, {'inactive': inactive}]" class="pagination-button">
    <img :src="icon"
         class="pagination-arrow"
         alt="pagination arrow"/>
  </div>
</template>

<script>
  export default {
    name: "PaginationButton",
    props: {
      goToPage: {
        type: Function,
        required: true
      },
      page: {
        type: Number,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      className: {
        type: String,
        required: false,
        default: ''
      },
      inactive: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "~@/assets/css/_base.variables.scss";
  @import "~@/assets/css/_base.mixins.scss";

  .pagination-button {
    @include hover-active-pointer();
    align-items: center;
    border: rem(1) solid var(--black-light);
    border-radius: rem(6);
    box-shadow: rem(2) rem(4) rem(8) 0 rgba(0,0,0,0);
    display: flex;
    height: rem(42);
    justify-content: center;
    width: rem(42);

    &.inactive {
      background-color: var(--gray-main);
      border: 3px solid var(--gray-main);

      @include on-hover {
        cursor: default;
      }

      .pagination-arrow {
        opacity: 0.3;
      }
    }
  }

  .pagination-arrow {
    height: rem(24);
    width: rem(24);
  }

  .pagination-back {
    margin-right: rem(14);
  }

  .disabled {
    opacity: 0.5;
  }
</style>
