<template>
  <div v-if="isDisplayed"
       @click="updatePage(page)"
       :class="className"
       class="pagination-item">
    {{page}}
  </div>
</template>

<script>
  export default {
    name: "PaginationPage",
    props: {
      isDisplayed: {
        type: Boolean,
        required: true
      },
      page: {
        type: Number,
        required: true
      },
      className: {
        type: String,
        required: false,
        default: ''
      },
      updatePage: {
        type: Function,
        required: false,
        default: () => {}
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "~@/assets/css/_base.variables.scss";
  @import "~@/assets/css/_base.mixins.scss";

  .pagination-item {
    align-items: center;
    display: flex;
    font-family: "Open Sans";
    font-size: rem(16);
    height: rem(34);
    justify-content: center;
    line-height: rem(18);
    text-align: center;
    width: rem(26);

    &:not(:last-of-type) {
      margin-right: rem(14);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .active-item {
    background-color: var(--gray-main);
    border-radius: rem(3);
    color: var(--black-light);
    font-weight: bold;
  }
</style>
