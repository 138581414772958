<template>
  <div class="basic-pagination-wrapper">
    <!-- Pagination -->
    <div class="pagination-box">

      <!-- Go to previous page button -->
      <PaginationButton :icon="require('../../../assets/icons/svg/ic_back_active.svg')"
                        :page="previousPage"
                        :inactive="previousPage === currentPage"
                        class-name="pagination-back"
                        :go-to-page="updatePage"/>

      <!-- First page -->
      <PaginationPage :page="1" :is-displayed="paginationData.currentPage > 2" :update-page="updatePage"/>

      <!-- Dots -->
      <div v-if="previousPage - firstPage >= 2" class="pagination-item item-dots">...</div>

      <!-- Previous page -->
      <PaginationPage :page="previousPage"
                      :is-displayed="previousPage !== paginationData.currentPage && paginationData.previous !== firstPage"
                      :update-page="updatePage"
                      class-name="item-previous"/>

      <!-- Current page -->
      <PaginationPage :page="paginationData.currentPage" :is-displayed="true" class-name="active-item"/>

      <!-- Next page -->
      <PaginationPage :page="nextPage"
                      :is-displayed="nextPage !== paginationData.currentPage"
                      :update-page="updatePage"/>

      <!-- Dots -->
      <div v-if="paginationData.currentPage < paginationData.totalPages - 2" class="pagination-item item-dots">...</div>

      <!-- Last page -->
      <PaginationPage :page="lastPage" :is-displayed="lastPage !== nextPage" :update-page="updatePage"/>

      <!-- Go to next page button -->
      <PaginationButton :icon="require('../../../assets/icons/svg/ic_next_active.svg')"
                        :page="nextPage"
                        :inactive="nextPage === currentPage"
                        :go-to-page="updatePage"/>
    </div>
  </div>
</template>

<script>
import PaginationPage from "./PaginationPage";
import PaginationButton from "./PaginationButton";
import {computed, ref, watch} from "@vue/composition-api";

export default {
  name: "Pagination",
  components: {
    PaginationButton,
    PaginationPage
  },
  props: {
    paginationData: {
      type: Object,
      required: false
    },
    goToPage: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const firstPage = 1;
    const lastPage = computed(() => props.paginationData.totalPages);
    const previousPage = computed(() => props.paginationData.currentPage > 1 ? props.paginationData.currentPage - 1 : props.paginationData.currentPage);
    const nextPage = computed(() => props.paginationData.currentPage < lastPage.value ? props.paginationData.currentPage + 1 : props.paginationData.currentPage);
    const currentPage = ref(props.paginationData.currentPage);

    function updatePage(page) {
      if (page !== currentPage.value) {
        currentPage.value = page;
        props.goToPage(currentPage.value);
      }
    }

    watch(() => props.paginationData, (newValue) => {
      if (newValue) {
        currentPage.value = props.paginationData.currentPage;
      }
    }, {deep: true});

    return {
      firstPage,
      lastPage,
      previousPage,
      nextPage,
      currentPage,
      updatePage
    }
  },
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.basic-pagination-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: rem(59);
}

.pagination-box {
  align-items: center;
  display: flex;

  .item-dots {
    align-items: center;
    display: flex;
    font-size: rem(14);
    font-weight: bold;
    height: rem(38);
    justify-content: center;
    line-height: rem(18);
    text-align: center;
    width: rem(38);
  }
}
</style>
